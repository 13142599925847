@charset "US-ASCII";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.inputDisabled:disabled{
  background-color: #021246;
  color: white;

}
.ui.input>input{
  
   color: #0071ce;
}
 

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
#link2 {
  color: #ffffff;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  text-decoration: none; 
  margin-top: 15px;
  height: 29px; 
  width: 160px; 
  background-color: #0071ce;
  color: white;
  border: none;
  border-radius: 5px;
  background-image: linear-gradient(#08b35a, #006a38);
  font-weight: bold;
  text-align:inherit;
  text-align: center;
  padding-top: 15px;
 
}

#link { 
  color: white;
  font-weight: bold;
  padding-top: 10px;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
} 

.toastProgress {
  background: #0071ce !important;
}

input[type="date"]
{
  height: 25px;
    display:block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
    background-color: white;
    text-align: left;
    color: black;
    vertical-align: middle;
}
input[type="time"]
{
  height: 25px;
    display:block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
    background-color: white;
    text-align: left;
    color: black;
    vertical-align: middle;
}

.idInput{
  height: 25px;
  border: solid black 1px;
  border-radius: 5px;
  top: 10px;
  margin-top: 10px;
  width: 100%;
}

div#is-relative{
  max-width: 420px;
  position: relative;
}

/* El icono debe ser "position: absolute"
 * Ademas le damos un "display: block" y lo posicionamos */
#icon{
  position: absolute;
  display: block;
  bottom: 0.05rem;
  right: 1rem;
  background-color: white;
  user-select: none;
  cursor: pointer;
  height: 100%;
}
input.input{
  padding-right: 2.5rem;
}

.idLabel{
  font-weight: bold;
  color: white;
 /* align-items: start; */
  text-align: left;
}

.divPrincipal{
  background-image: url('./component/Background-gas.png');
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;   
  background-attachment: fixed;
}

.containerMenuP{
  /*background-image: url('./resources/bg_gas_nf.svg');*/
  background-image: url('./resources/bg-principal.png');
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;   
  background-attachment: fixed;
}

.button{
  height: 40px; 
  width: 80px; 
  background-color: #0071ce;
  color: white;
  border: none;
  border-radius: 5px;
  background-image: linear-gradient(#2590d1, #076ab1); 
}
.buttonLogin{
  height: 40px; 
  width: 160px; 
  background-color: #0071ce;
  color: white;
  border: none;
  border-radius: 5px;
  background-image: linear-gradient(#08b35a, #006a38);
  font-weight: bold;
}
.buttonSalir{
  height: 50px;
  width: 143px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 5px; 
  background-image: linear-gradient(#08b35a, #006a38); 
}
#form-tanque{
  width: 100%;
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  color: rgba(0,0,0,.87);
  border-radius: 0.28571429rem;
  transition: box-shadow .1s ease,border-color .1s ease;
  box-shadow: none; 
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
 
  textarea,
  input {
    font-size: 16px;
    /*touch-action: none;*/
  }
  textarea,
  input:focus {
    font-size: 16px;
    /*touch-action: none;*/
  }
}
textarea,
  input {
    font-size: 16px;
    /*touch-action: none;*/
  }
  textarea,
  input:focus {
    font-size: 16px;
    /*touch-action: none;*/
  }
.buttonVerde{
  height: 40px;
  width: 80px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 5px; 
  padding-left: 15px;
  background-image: linear-gradient(#08b35a, #006a38); 
}

.buttonRojo{
  height: 40px;
  width: 100px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 5px; 
  padding-left: 15px;
  background-image: linear-gradient(#b30816, #6a0000); 
}

#tabletr:hover{
  
  background: rgba(77, 128, 169, 0.411);

}

.select-css {
  display: block;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  font-weight: 400;
  color: #444;
  line-height: 1.3;
  padding: .4em 1.4em .3em .8em;
  width: 400px;
  max-width: 100%; 
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
  border-radius: .3em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#f7f7f7 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
 }
 .select-css::-ms-expand {
  display: none;
 }
 .select-css:hover {
  border-color: #888;
 }
 .select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222; 
  outline: none;
 }
 .select-css option {
  font-weight:normal;
 }

 .input-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222; 
  outline: none;
  
 }
 .input-css{
  height: 25px;
  border: solid rgba(7, 97, 192, 0.7);
  border-radius: 5px;
  top: 10px;
  margin-top: 10px;
  width: 98%;
 }



 .FormGroup1 {
	margin: 0 0 20px;
	padding: 0;
	border-style: none;
	background-color: #04195e;
	will-change: opacity, transform;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
	border-radius: 4px;
  min-width: 350px;
}

.FormRow1 {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
	border-top: 1px solid #021246;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}

.buttonPago {
	display: block;
	font-size: 16px;
	width: calc(100% - 30px);
	height: 40px;
	margin: 40px 15px 0;
	background-image: linear-gradient(#08b35a, #006a38); 
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6;
	border-radius: 4px;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
}

button:active {
	background-image: linear-gradient(#08b35a, #006a38); 
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
	transform: scale(0.99);
}
h1,
h3 {
	text-align: center;
}

body{
  background:black;
}

 
.container{ 
  margin:80px auto; 
  width: 60px; 
  height: 60px; 
  position:relative; 
  transform-origin:center bottom; 
  animation-name: flicker; 
  animation-duration:20ms; 
  animation-delay:400ms; 
  animation-timing-function: ease-in; 
  animation-iteration-count: infinite; 
  animation-direction: alternate; 
} 
 
.flame{ 
  bottom:0; 
  position:absolute; 
  border-bottom-right-radius: 50%; 
  border-bottom-left-radius: 50%; 
  border-top-left-radius: 50%; 
  transform:rotate(-45deg) scale(1.5,1.5); 
} 
 
.yellow{ 
  left:15px;  
  width: 30px; 
  height: 30px; 
  background:#0071ce;
  box-shadow: 0px 0px 9px 4px #0071ce; 
} 
 
.orange{ 
  left:10px;  
  width: 40px; 
  height: 40px; 
  background:#0071ce; 
  box-shadow: 0px 0px 9px 4px #0071ce; 
} 
 
.red{ 
  left:5px; 
  width: 50px; 
  height: 50px; 
  background:#0071ce; 
  box-shadow: 0px 0px 5px 4px #0071ce; 
} 
 
.white{ 
  left:15px;  
  bottom:-4px; 
  width: 30px; 
  height: 30px; 
  background:white; 
  box-shadow: 0px 0px 9px 4px white; 
} 
 
.circle{ 
  border-radius: 50%; 
  position:absolute;   
} 
 
.blue{ 
  width: 10px; 
  height: 10px; 
  left:25px; 
  bottom:-25px;  
  background: #0071ce; 
  box-shadow: 0px 0px 15px 10px #0071ce;  
} 
 
.black{ 
  width: 40px; 
  height: 40px; 
  left:10px; 
  bottom:-60px;   
  background: black; 
  box-shadow: 0px 0px 15px 10px black; 
} 
 
@keyframes flicker{ 
  0%   {transform: rotate(-1deg);} 
  20%  {transform: rotate(1deg);} 
  40%  {transform: rotate(-1deg);} 
  60%  {transform: rotate(0.5deg) scaleY(1.04);} 
  80%  {transform: rotate(-1deg) scaleY(0.92);} 
  100% {transform: rotate(1deg);} 
}
 