body{
    font-family: 'Roboto', sans-serif; 
   /* background-image: linear-gradient(white, #ddd); */
    background-repeat: no-repeat;
    background-size:auto; 
    justify-content: center;
}

#body-content{
    display: flex; 
    margin: auto;  
    background-color: #fff; 
    width: 60vmax; 
    border-radius: 2px; 
    align-self: center; 
    background-color: #0071ce;
    color: white;
   
}
@media screen and (-webkit-min-device-pixel-ratio:0) { 
 
  textarea,
  input {
    font-size: 16px;
    /*touch-action: none;*/
  }
  textarea,
  input:focus {
    font-size: 16px;
    /*touch-action: none;*/
  }
}
  
@media all and (min-width:321px) and (max-width: 699px) { 
    #form-btn{ 
        min-height: 45px; 
    }
    
    #body-content{ 
        display: flex; 
        flex-direction: column;  
        margin: auto;    
        background-color: #fff;  
        width: 45vmax;  
        border-radius: 2px;  
        align-self: center;  
        color: white;
    } 

   
  }

  textarea,
  input {
    font-size: 16px;
    /*touch-action: none;*/
  }
  textarea,
  input:focus {
    font-size: 16px;
    /*touch-action: none;*/
  }

#div-img{
    display: flex; 
    flex: 1; 
    background-color: rgb(0 113 206); 
    justify-content: center;  
}

#div-img img{
   /* content: url("../resources/LogoGasPetromar.png");*/
}

#div-form{
    display: flex; 
    flex: 1; 
    flex-direction: column;  
    justify-content: center;
}

#div-form span{
    margin-bottom: .5vmax; 
    font-size: 14px; 
    font-weight: bold;
}

#div-form input{
   /* height: 2vmax; 
    margin-bottom: 3vmax;*/
}

#form-btn{
    border: none; 
    height: 3vmax; 
    border-radius: 5px; 
    align-self: center; 
    margin-top: 2vmax;
    transition: background-color .15s;
    background-color: #e0e1e2;
}

#form-btn:hover{
    background-color: #cacbcd;
    cursor: pointer;
}


#sidepanel{
    display: flex;
    font-family: 'Roboto', sans-serif; 
    font-size: 12px;
    flex-direction: column;
    background-color: #0071ce;
        /*max-height: 100vh; */
    height: 100vh;
    width: 15vmax;
    padding: 0 .3vmax;
    position: sticky;
    top: 0;
}

#sidebtn{
    color: white;
    transition: background-color .2s;
    border-bottom: 2px solid whot;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    text-align: center;
    height: auto;
    justify-content: left;
    padding: 1vmin 0px;
    align-items: center;
    margin-top: 15px;
}

#sidebtn span{
    flex: 1;
}
.menuOption:hover{
  background-color: #001f6b;
    cursor: pointer;
}
#sidepanel div:hover{
  
}
/*
#sidepanel-logo{ 

    max-height: 150px; 
    max-width: 250px; 
    align-self: center;
    padding-top: 1vh;
    padding-left: 1vh;
    margin-left: 25%;
}*/

#sideimg1{
    content: url("../resources/account_circle_white_24dp.svg");
    height: 26px;
}

#sideimg2{
    content: url("../resources/pedidos.svg");
    height: 26px;
}

#sideimgchek{
    content: url("../resources/pedidos.svg");
    height: 26px;
}
#sideimg3{
    content: url("../resources/add.svg");
    height: 26px;
}
#sideimg5{
  content: url("../resources/logout.svg");
  height: 26px;
}

 
.sideselected{
    background-color: #001f6b;
}
/*
#expand{
    content: url("../resources/expand_more_black_24dp.svg");
    height: 26px;
}
*/

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 46px;
    height: 30px;
    right: 36px;
    top: 35px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #ffffff;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47; 
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.0em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }


  #top-menu{
    display: block;
    width: 100%;
    text-align: center;
    color: white; 
    font-Size: 12px;
}